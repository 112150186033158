.register__bg {
  background-image:  url("../assets/img/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
}
.register__wrap {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}
.register__title {
  color: #82bdc5;
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 auto 50px;
}
.register__form {
  align-items: center;
  background-color: #303030;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  max-width: 515px;
  padding: 70px 40px;
}
.register__form__field label {
  color: #d4d4d4;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0 auto 10px;
}
.register__form__field input {
  background-color: #3f3f3f;
  border: 0;
  border-radius: 5px;
  color: #d4d4d4;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 auto;
  outline: 0;
  padding: 16px;
}
.register__btn:hover,
.register__btn {
  height: 50px;
  background-color: #82bdc5;
  border-radius: 50px;
  color: #FFF;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  outline: 0;
  border: 0;
  width: 100%;
  margin: 0 auto 15px;
}
.register__btn:last-child:hover,
.register__btn:last-child {
  margin: 0 auto;
}
.register__btn:hover {
  box-shadow: 0 0 100px 100px rgb(0 0 0 / 25%) inset;
}
.register__btn.register__google:hover,
.register__btn.register__google {
  background-color: #4c8bf5;
}
.register__form__field__login {
  color: #d4d4d4;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}
.register__form__field__login a:hover,
.register__form__field__login a {
  color: #d4d4d4;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}