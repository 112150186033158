.header-bg {
  background-color: #3f3f3f;
  width: 100%;
  min-height: 70px;
  padding: 30px 15px;
}
.menu-container a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.menu-container a:hover {
  color: #82bdc5;
}

.menu-container .nav-link.active {
  color: #82bdc5;
}
