@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: start;
}

.grid.fr1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid.fr2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid.fr3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid.fr4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid.fr5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid.fr6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid.fr7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid.fr8 {
  grid-template-columns: repeat(8, 1fr);
}

.grid.fr9 {
  grid-template-columns: repeat(9, 1fr);
}

.grid.fr10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid.fr11 {
  grid-template-columns: repeat(11, 1fr);
}

.grid.fr12 {
  grid-template-columns: repeat(12, 1fr);
}

.ctllc_forms--subtitle {
  text-align: left;
  background: #000;
  color: #fff;
  padding: 15px;
}

.ctllc_forms--label {
  text-align: left;
  margin: auto;
}

label {
  display: block;
  text-align: left;
  margin: 0 auto 10px;
}

input,
select {
  display: block;
  width: 100%;
  padding: 10px;
}

input[type="radio"],
input[type="checkbox"] {
  width: auto;
  display: inline-block;
  margin: auto 15px auto auto;
}

input[type="radio"]+label {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
body,
p {
  font-family: "Poppins", sans-serif;
  color: #626262;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-regular {
  font-weight: 500;
}

.font-semi {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extra {
  font-weight: 800;
}

.font-black {
  font-weight: 800;
}

.primary {
  color: #82bdc5;
}

.secondary {
  color: #3f3f3f;
}

.body-bg {
  background-color: #f6f6f6;
}

.cl-btn {
  background-color: #82bdc5;
  border: none;
  border-radius: 50px;
  padding: 10px 30px;
}

.cl-btn-gray {
  background-color: #3f3f3f;
  border: none;
  border-radius: 50px;
  color: #fff;
  padding: 10px 30px;
}

.cl-btn-blue {
  background-color: #82bdc5;
  border: none;
  border-radius: 50px;
  color: #fff;
  padding: 10px 30px;
}

.cl-btn,
.cl-btn-gray a,
.cl-btn-blue a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.cl-btn input {
  max-width: 300px;
}

.cl-btn-blue.big,
.cl-btn-gray.big {
  padding: 20px 50px;
}

label {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}

input[type="text"],
select#country,
select#city,
select#state,
select,
input[type="date"] {
  background-color: #fff;
  border: none;
  min-height: 53px;
  padding: 15px;
  border-radius: 5px;
}

input[type="textarea"] {
  background-color: #fff;
  border: none;
  min-height: 150px;
  padding: 15px;
  border-radius: 5px;
}

input#own,
input#rent {
  padding: 20px !important;
  margin: 20px 30px 20px 10px;
}

form span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #df5858;
  font-size: 13px !important;
}

.form-check-input:checked {
  background-color: #82bdc5 !important;
  border-color: #82bdc5 !important;
}

.step-head-blue {
  color: #82bdc5;
  font-size: 40px;
  font-weight: 700;
}

.step-head-gray {
  color: #3f3f3f;
  font-size: 40px;
  font-weight: 700;
}

.pulse {
  animation: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

.mb {
  margin-bottom: 50px;
}

.py-5.mb {
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

.shedule8-container {
  border: 1px solid #dfe2e6;
  padding: 25px !important;
}

.questionair {
  margin-bottom: 0px !important;
}

body {
  background-color: #f6f6f6 !important;
}

.application-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 6px;
}

.application-pending {
  border-top: 5px solid #f2c572;
}

.application-complete {
  border-top: 5px solid #81a489;
}

.font-pending {
  color: #f2c572;
  font-size: 14px;
}

.font-complete {
  color: #81a489;
  font-size: 14px;
}

.font-error {
  color: #c04c31;
  font-size: 14px;
}

.round-outline-pending {
  border: 1px solid #f2c572;
  border-radius: 50px;
  margin-top: 30px;
}

.round-outline-complete {
  border: 1px solid #81a489;
  border-radius: 50px;
  margin-top: 30px;
}

.round-outline-preview {
  border: 1px solid #3f3f3f;
  border-radius: 50px;
  color: #3f3f3f;
  cursor: pointer;
  margin-top: 30px;
}

.date {
  font-size: 13px;
}

.removeItem {
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 20px;
  text-align: center;
  background: red;
  color: #FFF;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.support-button {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  height: 4rem;
  width: 4rem;
  border-radius: 1rem;
  background-color: #82bdc5;
  /* border-bottom: 2px solid black; */
  /* border-right: 2px solid black; */
  box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);
  background-image: url("./assets/chat.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5rem;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.support-button:hover {
  transform: translateY(-0.75rem);
  transition-duration: 300ms;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.loader {
  border: .25rem solid #f3f3f3;
  /* Light grey */
  border-top: .25rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}